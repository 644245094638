<template>
	<div>
		<el-dialog :title="$t('i18nn_ef8d08b45c898979')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile"
			width="1000px">
			<div class="">
				<!--本页切换列表-->
				<div>
					<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
						<div class="tableConTable">
							<el-button @click="openSelDialog()" type="primary" icon="el-icon-magic-stick"
								style="">
								{{$t("FormMsg.Select")}}
							</el-button>
							<el-input type="text" disabled v-model="form.csTrackerName" style="width:200px"></el-input>
							<!-- <el-tag>
								{{form.csTrackerName}}
							</el-tag> -->
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitAction()">{{$t('ad4345dbaabe1479')}}</el-button>
				<el-button type="primary" plain @click="dialogFile = false">{{ $t('4e9fc68608c60999') }}</el-button>
			</div>
		</el-dialog>
		<!--选择账号-->
		<!-- <el-dialog :title="$t('i18nn_b17342e22700eb32')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogSelVisible" width="800px" top="0px"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_b17342e22700eb32')" append-to-body :visible.sync="dialogSelVisible" :direction="'rtl'" size="800px">	
			<!-- <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div> -->
			<div>
				<AccountUserList @selectRow="selAccount"></AccountUserList>
			</div>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{$t('FormMsg.Close')}}</el-button>
			</div> -->
		</el-drawer>
		<!-- </el-dialog> -->
	</div>
</template>
<script>
	import AccountUserList from '@/components/WarehouseCenter2/AccountBind/AccountUserList.vue';

	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {},
			row: {}
		},
		components: {
			AccountUserList
		},
		data() {
			return {
				dialogFile: false,
				loading_load: false,
				
				dialogSelVisible:false,

				//表格数据
				//loading,表格数据
				loading: false,
				// leaveMessage:"",

				//表格数据
				// fedexData: {},
				// upsData:{},
				// dataOpenTime:"",
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 300],
				// 	page_size: 200,
				// 	total: 0
				// },
				form: {
					csTracker: "",
					csTrackerName: "",
				},
				// selectOption: {
				// 	wh_no: [],
				// },
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc', //desc降序，asc升序

				// 	// orderNumber: '',
				// 	// "id": null, //"数据ID",
				// 	// "carrier": null, //"物流公司",
				// 	// "tracking": null, //this.$t('i18nn_4531476fa35570f0')
				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			console.log(' activated');
		},
		//创建时
		created() {
			console.log(' created');
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			goBack() {

			},

			initData() {
				// this.filterData.id = this.row.id;
				// this.leaveMessage = "";
				// this.getDetData();
				this.form = {
					csTracker: this.row.csTracker,
					csTrackerName: this.row.csTrackerName,
				};
			},
			//选择账号
			selAccount(row) {
				console.log('selAccount', row);
				this.form.csTracker = row.userId;
				this.form.csTrackerName = row.userName;
				this.dialogSelVisible = false;
			},

			openSelDialog() {
				this.dialogSelVisible = true;
			},
			// 富文本
			// onEditorChange(html) {
			//   // this.form.contentStr = html;
			// 	this.leaveMessage = html;
			// },
			// //请求数据
			// getDetData() {

			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhQARecMoreText+"?keyId="+this.filterData.id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				this.leaveMessage = data.data;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error(this.$t('hytxs0000030'));
			// 			this.loading_load = false;
			// 		});
			// },
			submitAction() {
				// if(!this.leaveMessage){
				// 	this.$message.warning(this.$t('i18nn_efbfa769d113b9fb'))
				// 	return;
				// }
				let formData = Object.assign({}, this.form);
				formData.ids = [this.row.id];

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhQAUpdateTrackingAcc, formData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							this.$message.success(this.$t('i18nn_b93f7c8c4e2346f8'));
							this.dialogFile = false;
							this.$emit("success");
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a7d2e953195a5588'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('i18nn_a7d2e953195a5588'));
						this.loading_load = false;
					});
			},
			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
